/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-console */
import React, { useState, useEffect, useCallback, useContext } from 'react'
import styled from 'styled-components'
import { instantMeiliSearch as instantMeilisearch } from '@meilisearch/instant-meilisearch'
import { MeiliSearch as Meilisearch } from 'meilisearch'

import { useMeilisearchClientContext } from 'context/MeilisearchClientContext'
import useLocalStorage from 'hooks/useLocalStorage'
import Body from 'components/Body'
import CloudBanner from 'components/CloudBanner'
import NoMeilisearchRunning from 'components/NoMeilisearchRunning'
import ApiKeyAwarenessBanner from 'components/ApiKeyAwarenessBanner'
import getIndexesListWithStats from 'utils/getIndexesListWithStats'
import shouldDisplayCloudBanner from 'utils/shouldDisplayCloudBanner'
import hasAnApiKeySet from 'utils/hasAnApiKeySet'
import ApiKeyContext from 'context/ApiKeyContext'
import clientAgents from './version/client-agents'

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.colors.gray[11]};
  min-height: 100vh;
`

const Dashboard = () => {
  const context = useContext(ApiKeyContext)
  const { apiKey, setApiKey, baseUrl } = context
  const [indexes, setIndexes] = useState()
  const [isMeilisearchRunning, setIsMeilisearchRunning] = useState(false)
  const [requireApiKeyToWork, setRequireApiKeyToWork] = useState(false)
  const [currentIndex, setCurrentIndex] = useLocalStorage('currentIndex')
  const [showCloudBanner, setShowCloudBanner] = useState(false)
  const [isApiKeyBannerVisible, setIsApiKeyBannerVisible] = useState(false)

  const {
    meilisearchJsClient,
    setMeilisearchJsClient,
    setInstantMeilisearchClient,
  } = useMeilisearchClientContext()

  const getIndexesList = useCallback(async () => {
    try {
      const indexesList = await getIndexesListWithStats(meilisearchJsClient)
      setIndexes(indexesList)
      if (indexesList && indexesList?.length > 0) {
        setCurrentIndex(
          currentIndex
            ? indexesList.find((option) => option.uid === currentIndex.uid)
            : indexesList[0]
        )
      } else {
        setCurrentIndex(null)
      }
    } catch (error) {
      setCurrentIndex(null)
      console.log(error)
    }
  }, [meilisearchJsClient, currentIndex])

  // Check if the API key is present on the url then put it in the local storage
  const getApiKeyFromUrl = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const apiKeyParam = urlParams.get('api_key')
    if (apiKeyParam) {
      setApiKey(apiKeyParam)
      setIsApiKeyBannerVisible(true)
    }
  }, [])

  useEffect(() => {
    const shouldCloudBannerBeDisplayed = shouldDisplayCloudBanner()
    if (shouldCloudBannerBeDisplayed) {
      setShowCloudBanner(shouldCloudBannerBeDisplayed)
    }
    getApiKeyFromUrl()
  }, [])

  useEffect(() => {
    setInstantMeilisearchClient(
      instantMeilisearch(baseUrl, apiKey, {
        primaryKey: 'id',
        clientAgents,
      }).searchClient
    )

    setMeilisearchJsClient(
      new Meilisearch({
        host: baseUrl,
        apiKey,
        clientAgents,
      })
    )
  }, [apiKey])

  useEffect(() => {
    const onClientUpdate = async () => {
      const isInstanceRunning = await meilisearchJsClient.isHealthy()
      setIsMeilisearchRunning(isInstanceRunning)
      if (isInstanceRunning) {
        setRequireApiKeyToWork(await hasAnApiKeySet(baseUrl))
        // dialog.setVisible(await shouldDisplayApiKeyModal(meilisearchJsClient))
        getIndexesList()
      }
    }
    onClientUpdate()
  }, [meilisearchJsClient])

  return (
    <Wrapper>
      {isApiKeyBannerVisible && (
        <ApiKeyAwarenessBanner
          onClose={() => setIsApiKeyBannerVisible(false)}
        />
      )}
      {showCloudBanner && <CloudBanner />}
      {isMeilisearchRunning ? (
        <Body
          currentIndex={currentIndex}
          indexes={indexes}
          setCurrentIndex={setCurrentIndex}
          requireApiKeyToWork={requireApiKeyToWork}
          getIndexesList={getIndexesList}
          isApiKeyBannerVisible={isApiKeyBannerVisible}
        />
      ) : (
        <NoMeilisearchRunning />
      )}
    </Wrapper>
  )
}

export default Dashboard
