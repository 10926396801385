/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { useDialogState } from 'reakit/Dialog'
import ApiKeyModalContent from 'components/ApiKeyModalContent'
import Modal from 'components/Modal'
import ApiKeyContext from 'context/ApiKeyContext'
import useLocalStorage from 'hooks/useLocalStorage'
import { MeiliSearchClientProvider } from 'context/MeilisearchClientContext'
import Dashboard from 'Dashboard'

const App = () => {
  const [requireApiKeyToWork] = useState(false)
  const dialog = useDialogState({ animated: true, visible: false })
  const [apiKey, setApiKey] = useLocalStorage('apiKey')
  const [baseUrl, setBaseUrl] = useLocalStorage('baseUrl')

  useEffect(() => {
    if (!apiKey || !baseUrl) {
      dialog.show()
    }
  }, [baseUrl, apiKey])

  return (
    <ApiKeyContext.Provider value={{ apiKey, setApiKey, baseUrl, setBaseUrl }}>
      <Modal
        title={`Enter your admin API key${
          requireApiKeyToWork ? '' : ' (optional)'
        }`}
        dialog={dialog}
        ariaLabel="ask-for-api-key"
      >
        <ApiKeyModalContent closeModal={() => dialog.hide()} />
      </Modal>
      {baseUrl && apiKey && (
        <MeiliSearchClientProvider>
          <Dashboard />
        </MeiliSearchClientProvider>
      )}
    </ApiKeyContext.Provider>
  )
}

export default App
